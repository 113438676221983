import React from "react";
import MohitImg from "../../Assets/imgs/mohit-mob.png";

const Founder_responsive = () => {
  return (
    <div className="" style={{ textAlign: "center" }}>
      <img src={MohitImg} alt="" className="w-full" />
    </div>
  );
};

export default Founder_responsive;
